<template>
  <div>
    <navbar :nome_escola="1" :isRelatorio="1"/>
    <!-- menu vertical -->
    <nav id="menu-relatorio" class="left show">
      <i class="fa-solid fa-left-long"></i>

      <ul style="margin-top: 15px;">
        <li v-if="this.funcao == 1 || this.funcao == 2 || this.funcao == 7">
          <router-link to="/admin/painel-adm"><i class="fa fa-angle-left"></i> Ambiente Educacional</router-link>
        </li>
        <li v-if="this.funcao == 3 || this.funcao == 4 ">
          <router-link to="/ambiente-coordenacao/coordenacao-escolas"><i class="fa fa-angle-left"></i> Ambiente Educacional</router-link>
        </li>
      </ul>

      <h3 style="padding-top: 2px;padding-left: 20px;font-size: 20px;">Relatórios</h3>

      <ul style="margin-top: 15px;">
        <!--<li><router-link to="/relatorios/dashboard" class="active"><i class="fa fa-home"></i> Dashboard </router-link></li>-->
        <li><router-link to="/relatorios/ficha-individual"><i class="fa fa fa-laptop"></i> Ficha Individual</router-link></li>
        <li><router-link to="/relatorios/resultados-finais"><i class="fa fa fa-laptop"></i> Resultados Finais</router-link></li>
        <li><router-link to="/relatorios/livro-matricula"><i class="fa fa fa-laptop"></i> Livro de Matricula</router-link></li>
        <li><router-link to="/relatorios/atas"><i class="fa fa fa-laptop"></i> Atas</router-link></li>

        <!--<li><router-link to="/relatorios/livro-matricula"><i class="fa fa fa-laptop"></i> Quardo Formação Turma</router-link></li>-->
        <li><router-link to="/relatorios/boletim-notas"><i class="fa fa fa-laptop"></i> Boletim de Notas </router-link></li>
        <li><router-link to="/relatorios/conteudos-aplicados"><i class="fa fa fa-laptop"></i> Conteúdos Aplicados </router-link></li>
        <li><router-link to="/relatorios/calendario-vacinacao"><i class="fa fa fa-laptop"></i> Calendário de Vacinação </router-link></li>

        <!--<li><router-link to="/relatorios/aluno-transporte"><i class="fa fa fa-laptop"></i> Transporte</router-link></li>
        <li><router-link to="/relatorios/saude-social"><i class="fa fa fa-laptop"></i> Saúde Social</router-link></li>
        <li><router-link to="/relatorios/aluno-especial"><i class="fa fa fa-laptop"></i>Monitorar Alunos PNE</router-link></li>-->

        <li><router-link to="/relatorios/configuracao-relatorio"><i class="fa fa fa-laptop"></i>Configuração</router-link></li>


      </ul>
      <a href="#" id="showmenu">
        <i class="fa fa-align-justify"></i>
      </a>
    </nav>
    <!-- /menu vertical -->

    <div id="container" style="background: #ffffff !important;">
      <router-view/>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import { defineComponent } from 'vue'
  import { LoginInfo } from "@/class/login";

  import Navbar from '@/components/navbar/Navbar.vue';

  export default defineComponent({
    components: {
      Navbar,
    },
    data(){
      return{
        funcao:0,
      }
    },
    methods: {
      async logado () {
        try{
          const token = sessionStorage.getItem("token");
          const whoiam = await LoginInfo.WhoIam(token);
          this.funcao = whoiam.data.funcao;

          if(whoiam.data.status == "Token is Expired"){
            this.$router.push({ name: "login"});
          }
          sessionStorage.removeItem("escolas");
        }catch(e){ this.$router.push({ name: "login"}); }
      }
    },
    mounted(){
      this.logado();

      $("#showmenu").click(function(e){
        $("#menu-relatorio").toggleClass("show");
        $("#container").toggleClass("cont-left");
      });
      $("#menu-relatorio a").click(function(event){
        if($(this).next('ul').length){
          event.preventDefault();
          $(this).next().toggle('fast');
          $(this).children('i:last-child').toggleClass('fa-caret-down fa-caret-left');
        }
      });
    }
  });
</script>

<style>

#menu-relatorio{
	position:fixed;
	background-color:#222;
	height:100%;
	z-index:10;
	width:280px;
	color:#bbb;
	top:0;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	opacity:1;
	font-family: 'Source Sans Pro', sans-serif;
}

#menu-relatorio ul{
	list-style:none;
	margin-top:0;
	padding:0
}
#menu-relatorio ul li{border-bottom: 1px solid #2a2a2a;}
#menu-relatorio>ul>li>a{border-left:4px solid #222;}
#menu-relatorio ul li a{
	color:inherit;
	font-size:16px;
	display:block;
	padding:8px 0 8px 7px;
	text-decoration:none;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	font-weight: 600;
}
#menu-relatorio ul a i{
	margin-right:10px;
	font-size: 18px;
	margin-top: 3px;
	width: 20px;
}
#menu-relatorio ul a i[class*='fa-caret']{float: right;}
#menu-relatorio ul a:hover,#menu-relatorio ul a.active{
	background-color:#111;
	border-left-color: #288ebf;
	color:#288ebf;
}
#menu-relatorio ul a:hover i:first-child{color:#288ebf;}

	/* Submenu */
	#menu-relatorio ul li a.active+ul{display:block}
	#menu-relatorio ul li ul{margin-top: 0;display: none;}
	#menu-relatorio ul li ul li{border-bottom: none;}
	#menu-relatorio ul li ul li a{padding-left: 30px;}
	#menu-relatorio ul li ul li a:hover{
		background-color:#1A1A1A;
	}
	/* /Submenu */


/* Cuando este a la Izq, para esconderlo posicionarlo a la Izq a -width */
.left{left:-280px;}
.show{left:0;}

#showmenu{
	margin-left:100%;
	position:absolute;
	top:0;
	padding:6px 10px 7px 10px;
	font-size:1.3em;
	color:#288ebf;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

#container{padding: 20px; padding-top: 50px; margin-left: 280px;}
.cont-left{
  margin-left: 0px !important;
}
</style>
